import React from 'react';
import { Table } from '@mantine/core';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';
import Tabs from '../../../components/Tabs';
import sanu from '../../../assets/images/departments/sanu.png';
import oredugba from '../../../assets/images/departments/oredugba.png';
import dacosta from '../../../assets/images/departments/dacosta.png';
import utomi from '../../../assets/images/departments/utomi.png';
import orenuga from '../../../assets/images/departments/orenuga.png';
import umeh from '../../../assets/images/departments/umeh.png';
import isiekwe from '../../../assets/images/departments/isiekwe.png';
import olatosi from '../../../assets/images/departments/olatosi.png';

const ChildDental = () => {
  const resident = [
    {
      name: 'Dr. Adesuwa Abe',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Benin)',
      mdcn: '38,951',
    },
    {
      name: 'Dr. B.O. Akindele',
      designation: 'Snr. Registrar',
      qualification: 'B.ch.D (Ife)',
      mdcn: '71,371',
    },
    {
      name: 'Dr. O.A Osibogun',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Ibadan)',
      mdcn: '66,062',
    },
    {
      name: 'Dr A.R Majekodunmi',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '64,918',
    },
    {
      name: 'Dr. T.S Jauro',
      designation: 'Registrar',
      qualification: 'BDS ',
      mdcn: '80,333',
    },
    {
      name: 'Dr O.T Odunlami',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '85,676',
    },
    {
      name: 'Dr C.T Adeniji',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '64,912',
    },
    {
      name: 'Dr T. Alakwem',
      designation: 'Registrar',
      qualification: 'BDS (Benin)',
      mdcn: '73,367',
    },
  ];

  const orthodontics = [
    {
      name: 'Dr. S.E. Chegwe',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Benin)',
      mdcn: '1401',
    },
    {
      name: 'Dr. M.O. Akeredolu',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '51,848',
    },
    {
      name: 'Dr. A. Ben-Okoye',
      designation: 'Snr. Registrar',
      qualification: 'B.CDH (Ife)',
      mdcn: '63,801',
    },
    {
      name: 'Dr. O. Ayenakin',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Benin)',
      mdcn: '62,663',
    },
    {
      name: 'Dr A. Koledoye',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Ibadan)',
      mdcn: '60,998',
    },
    {
      name: 'Dr O.T Odunlami',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '85,676',
    },
    {
      name: 'Dr A.Y Adeloye',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '35,643',
    },
    {
      name: 'Dr. Odah',
      designation: 'Snr. Registrar',
      qualification: 'BDS (Maiduguri)',
      mdcn: '67,286',
    },
    {
      name: 'Dr O.U Obidi',
      designation: 'Registrar',
      qualification: 'BDS (Benin)',
      mdcn: '65,626',
    },
    {
      name: 'Dr. O.A Temiye',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '74,307',
    },
    {
      name: 'Dr A.S Eniola',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '74,310',
    },
    {
      name: 'Dr H.A Nnodebe',
      designation: 'Registrar',
      qualification: 'BDS',
      mdcn: '59,427',
    },
    {
      name: 'Dr N.A Ajie',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '81,527',
    },
    {
      name: 'Dr N.M Awwal',
      designation: 'Registrar',
      qualification: 'BDS (Maiduguri)',
      mdcn: '65,832',
    },
    {
      name: 'Dr O Olu-Awe',
      designation: 'Registrar',
      qualification: 'BDS (Lagos)',
      mdcn: '89,943',
    },
    {
      name: 'Dr T.O Ogunye',
      designation: 'Registrar',
      qualification: 'BChD (Ife)',
      mdcn: '86,659',
    },
  ];

  const support = [
    {
      name: 'Miss M.A. Saliu',
      designation: 'Dental Therapist',
      qualification: 'HND, (Ilesa, Ogun)',
    },
    {
      name: 'Mr. Anothony E. Eze',
      designation: 'Dental Technologist',
      qualification: 'HND, (Enugu)',
    },
    {
      name: 'Mrs. J.O. Aponmade',
      designation: 'Chief. Confidential Secretary',
      qualification: 'Diploma in Secretarial Studies',
    },
    {
      name: 'Mrs. Taiwo Ige',
      designation: 'Chief Clerical Officer',
      qualification: '',
    },
  ];

  const residentRows = (type) => {
    return type.map((element) => (
      <tr key={element.name}>
        <td>{element.name}</td>
        <td>{element.designation}</td>
        <td>{element.qualification}</td>
        <td>{element.mdcn}</td>
      </tr>
    ));
  };

  const supportRows = support.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.designation}</td>
      <td>{element.qualification}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Department of Child Dental Health | LUTH'>
      <NavOne />
      <PageHeader title='Department of Child Dental Health' />
      {/* <DepartmentDetails title='Department of Child Dental Health' /> */}
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Child Dental Health
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <p className='course-details__tab-text'>
                      The Department of Child Dental Health was created on
                      August 1st, 1980 following the approval of the University
                      Senate on June 17th, 1980.
                      <br /> <br /> It was carved out of the Department of
                      Restorative Dentistry. The first Head of Department was
                      Dr. J.O. Adenubi, 1980 – 1983. <br /> <br />
                      It consists of two units namely Paediatric Dentistry and
                      Orthodontics. Paediatric Dentistry is the aspect of
                      Dentistry that deals with oral health needs of children
                      from birth to fifteen years including the management of
                      children and persons with special health needs.
                      Orthodontics deals with management of malocclusion in both
                      children and adults.
                      <br /> <br />
                      The Department presently has 8 honorary consultants to the
                      Lagos University Teaching Hospital. The Department
                      participates actively in dental clinical services and
                      members make contributions in the areas of teaching of
                      resident doctors and other health professional in clinical
                      services and research.
                      <br /> <br /> The Department has one Dental Technologist,
                      one Dental Therapist, one Principal Confidential Secretary
                      and one Clerical staff. <br /> <br />
                      The Department also carries out training of Registrars
                      (Junior and Senior) for the West African Postgraduate
                      Medical College and the National Postgraduate Medical
                      College. The junior Registrar is expected to rotate
                      through Paediatric Dentistry as well as Orthodontic units.
                      <br /> <br /> In the last 20 years the Department has
                      trained over 40 Paediatric Dentists and Orthodontists, who
                      are gainfully employed in both State and Federal
                      Institutions; and some in full time private practice. The
                      Department continues to engage in high quality research.{' '}
                      <br /> <br />
                      The goal of the Department is the establishment of two
                      separate functional Departments namely Paediatric
                      Dentistry and Orthodontics. The proposal for postgraduate
                      academic programs in MSc, MPhil and PhD in both Paediatric
                      Dentistry and Orthodontics is currently being processed
                      with the Academic Planning Unit of the University of
                      Lagos.
                    </p>
                  </div>
                  <div label='Services'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Paediatric Dentistry Unit
                    </h2>
                    <p className='course-details__tab-text'>
                      1. Oral Prophylaxis <br />
                      2. Use of fluoride and professional application <br />
                      3. Application of fissure sealants <br />
                      4. Restorations including GIC, Composite and Amalgam{' '}
                      <br />
                      5. Root canal therapy on anterior and posterior teeth{' '}
                      <br />
                      6. Pulp therapy <br />
                      7. Management of trauma to anterior teeth <br />
                      8. Stainless Steel Crowns <br />
                      9. Labial facing of anterior teeth
                      <br />
                      10. Acrylic and porcelain jacket crown on anterior teeth
                      <br />
                      11. Stainless steel crowns <br />
                      12. Pharmacologic and non-pharmacologic behavior guidance
                      of child patients <br />
                      13. Application of fissure sealants, topical fluoride{' '}
                      <br />
                      14. Routine exodontias for child patients <br />
                      15. Management of children with special needs <br />
                      16. Management of children with cleft lip and palate{' '}
                      <br />
                      17. Fabrication of cast core gold posts <br />
                      18. Basic orthodontic procedures with fabrication of
                      simple orthodontic appliances (build breaker and space
                      maintainers) and partial dentures <br />
                      19. Minor oral surgery (oral pathology, histology) and
                      exodontias <br />
                      20. Prescription writing and referrals
                    </p>
                    <h2 className='blog-one__title' style={{ marginTop: 22 }}>
                      Orthodontics Unit
                    </h2>
                    <p className='course-details__tab-text'>
                      1. Diagnosis and treat all orthodontic problems using
                      removable and fixed appliances. <br />
                      2. Carry out Interceptive orthodontics <br />
                      3. Manage Class I, II, III Malocclusions <br />
                      4. Carry out Orthodontic management of Cleft lip and
                      palate patients <br />
                      5. Carry out Inter-disciplinary management of appropriate
                      cases
                    </p>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={sanu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. O.O. Sanu</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/Consultant Head of Dept.
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Ibadan) FWACS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oredugba} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. F.A. Oredugba</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Benin), FWACS, MPH (Lagos), M SND RCSEd, FADPD,
                            FDSRCSED
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dacosta} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. O.O. daCosta</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={utomi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. I.L. Utomi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos) FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={orenuga} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.O. Orenuga</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, MPH (Lagos), CCRA (Canada)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olatosi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.O. Olatosi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos), FMCDS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Dentistry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={isiekwe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. G.I. Isiekwe</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, (Lagos), FMCDS, MPH (Lagos)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={umeh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.D. Umeh</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Benin) FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthodontics
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Paediatric Dentistry Resident Doctors
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>MDCN</th>
                          </tr>
                        </thead>
                        <tbody>{residentRows(resident)}</tbody>
                      </Table>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Orthodontics Resident Doctors
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>MDCN</th>
                          </tr>
                        </thead>
                        <tbody>{residentRows(orthodontics)}</tbody>
                      </Table>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Support Staff: Clinical / Administrative Services
                      </h2>
                      <Table fontSize='md' striped>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                          </tr>
                        </thead>
                        <tbody>{supportRows}</tbody>
                      </Table>
                    </React.Fragment>
                  </div>
                </Tabs>
                {/* <ul className='course-details__tab-navs list-unstyled nav nav-tabs'>
                  <li>
                    <a role='tab' data-toggle='tab' href='#overview'>
                      Sub-Specialties
                    </a>
                  </li>
                  <li>
                    <a
                      className='active'
                      role='tab'
                      data-toggle='tab'
                      href='#curriculum'
                    >
                      Curriculum
                    </a>
                  </li>
                  <li>
                    <a className='' role='tab' data-toggle='tab' href='#review'>
                      Reviews
                    </a>
                  </li>
                </ul>
                <div className='tab-content course-details__tab-content '>
                  <div
                    className='tab-pane animated fadeInUp'
                    role='tabpanel'
                    id='overview'
                  >
                    <ul className='list-unstyled course-details__overview-list'>
                      <li>It has survived not only five centuries</li>
                      <li>
                        Lorem Ipsum is simply dummy text of the new design
                      </li>
                      <li>Printng and type setting ipsum</li>
                      <li>Take a look at our round up of the best shows</li>
                    </ul>
                  </div>
                  <div
                    className='tab-pane show active animated fadeInUp'
                    role='tabpanel'
                    id='curriculum'
                  >
                    <h3 className='course-details__tab-title'>
                      Starting beginners level course
                    </h3>
                    <br /> <br />
                    <p className='course-details__tab-text'>
                      Aelltes port lacus quis enim var sed efficitur turpis
                      gilla sed sit Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry. Lorem Ipsum has been
                      the industry’s standard dummy text ever since.
                    </p>
                    <br /> <br />
                    <ul className='course-details__curriculum-list list-unstyled'>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon video-icon'>
                            <i className='fas fa-play'></i>
                          </div>
                          <a href='#none'>Introduction to Editing</a>{' '}
                          <span>Preview</span>
                        </div>
                        <div className='course-details__curriculum-list-right'>
                          16 minutes
                        </div>
                      </li>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon video-icon'>
                            <i className='fas fa-play'></i>
                          </div>
                          <a href='#none'>Overview of Editing</a>{' '}
                          <span>Preview</span>
                        </div>
                        <div className='course-details__curriculum-list-right'>
                          10 minutes
                        </div>
                      </li>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon file-icon'>
                            <i className='fas fa-folder'></i>
                          </div>
                          <a href='#none'>Basic Editing Technology</a>
                        </div>
                      </li>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon quiz-icon'>
                            <i className='fas fa-comment'></i>
                          </div>
                          <a href='#none'>Quiz</a>
                        </div>
                        <div className='course-details__curriculum-list-right'>
                          6 questions
                        </div>
                      </li>
                    </ul>
                    <br /> <br />
                    <br /> <br />
                    <h3 className='course-details__tab-title'>
                      Intermediate Level
                    </h3>
                    <br /> <br />
                    <p className='course-details__tab-text'>
                      Aelltes port lacus quis enim var sed efficitur turpis
                      gilla sed sit Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry. Lorem Ipsum has been
                      the industry’s standard dummy text ever since.
                    </p>
                    <br /> <br />
                    <ul className='course-details__curriculum-list list-unstyled'>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon video-icon'>
                            <i className='fas fa-play'></i>
                          </div>
                          <a href='#none'>Introduction to Editing</a>
                          <span>Preview</span>
                        </div>
                        <div className='course-details__curriculum-list-right'>
                          16 minutes
                        </div>
                      </li>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon file-icon'>
                            <i className='fas fa-folder'></i>
                          </div>
                          <a href='#none'>Basic Editing Technology</a>
                        </div>
                      </li>
                      <li>
                        <div className='course-details__curriculum-list-left'>
                          <div className='course-details__meta-icon quiz-icon'>
                            <i className='fas fa-comment'></i>
                          </div>
                          <a href='#none'>Quiz</a>
                        </div>
                        <div className='course-details__curriculum-list-right'>
                          6 questions
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className='tab-pane  animated fadeInUp'
                    role='tabpanel'
                    id='review'
                  >
                    <div className='row'>
                      <div className='col-xl-7 d-flex'>
                        <div className='course-details__progress my-auto'>
                          <div className='course-details__progress-item'>
                            <p className='course-details__progress-text'>
                              Excellent
                            </p>
                            <div className='course-details__progress-bar'>
                              <span style={{ width: `95%` }}></span>
                            </div>
                            <p className='course-details__progress-count'>5</p>
                          </div>
                          <div className='course-details__progress-item'>
                            <p className='course-details__progress-text'>
                              Very Good
                            </p>
                            <div className='course-details__progress-bar'>
                              <span style={{ width: `65%` }}></span>
                            </div>
                            <p className='course-details__progress-count'>2</p>
                          </div>
                          <div className='course-details__progress-item'>
                            <p className='course-details__progress-text'>
                              Average
                            </p>
                            <div className='course-details__progress-bar'>
                              <span style={{ width: `33%` }}></span>
                            </div>
                            <p className='course-details__progress-count'>1</p>
                          </div>
                          <div className='course-details__progress-item'>
                            <p className='course-details__progress-text'>
                              Poor
                            </p>
                            <div className='course-details__progress-bar'>
                              <span
                                style={{ width: `0%` }}
                                className='no-bubble'
                              ></span>
                            </div>
                            <p className='course-details__progress-count'>0</p>
                          </div>
                          <div className='course-details__progress-item'>
                            <p className='course-details__progress-text'>
                              Terrible
                            </p>
                            <div className='course-details__progress-bar'>
                              <span
                                style={{ width: `0%` }}
                                className='no-bubble'
                              ></span>
                            </div>
                            <p className='course-details__progress-count'>0</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-xl-5 justify-content-xl-end justify-content-sm-center d-flex'>
                        <div className='course-details__review-box'>
                          <p className='course-details__review-count'>4.6</p>
                          <div className='course-details__review-stars'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star-half'></i>
                          </div>
                          <p className='course-details__review-text'>
                            30 reviews
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='course-details__comment'>
                      <div className='course-details__comment-single'>
                        <div className='course-details__comment-top'>
                          <div className='course-details__comment-img'>
                            <img src={team1} alt='' />
                          </div>
                          <div className='course-details__comment-right'>
                            <h2 className='course-details__comment-name'>
                              Steven Meyer
                            </h2>
                            <div className='course-details__comment-meta'>
                              <p className='course-details__comment-date'>
                                26 July, 2019
                              </p>
                              <div className='course-details__comment-stars'>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star star-disabled'></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className='course-details__comment-text'>
                          Lorem ipsum is simply free text used by copytyping
                          refreshing. Neque porro est qui dolorem ipsum quia
                          quaed inventore veritatis et quasi architecto beatae
                          vitae dicta sunt explicabo.
                        </p>
                      </div>
                      <div className='course-details__comment-single'>
                        <div className='course-details__comment-top'>
                          <div className='course-details__comment-img'>
                            <img src={team2} alt='' />
                          </div>
                          <div className='course-details__comment-right'>
                            <h2 className='course-details__comment-name'>
                              Lina Kelley
                            </h2>
                            <div className='course-details__comment-meta'>
                              <p className='course-details__comment-date'>
                                26 July, 2019
                              </p>
                              <div className='course-details__comment-stars'>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star star-disabled'></i>
                                <i className='fa fa-star star-disabled'></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className='course-details__comment-text'>
                          Lorem ipsum is simply free text used by copytyping
                          refreshing. Neque porro est qui dolorem ipsum quia
                          quaed inventore veritatis et quasi architecto beatae
                          vitae dicta sunt explicabo.
                        </p>
                      </div>
                    </div>
                    <form action='#' className='course-details__comment-form'>
                      <h2 className='course-details__title'>Add a review</h2>
                      <p className='course-details__comment-form-text'>
                        Rate this Course?{' '}
                        <a href='#give-star' aria-label='review stars'>
                          <i className='fas fa-star'></i>
                        </a>
                        <a href='#give-star' aria-label='review stars'>
                          <i className='fas fa-star'></i>
                        </a>
                        <a href='#give-star' aria-label='review stars'>
                          <i className='fas fa-star'></i>
                        </a>
                        <a href='#give-star' aria-label='review stars'>
                          <i className='fas fa-star'></i>
                        </a>
                        <a href='#give-star' aria-label='review stars'>
                          <i className='fas fa-star'></i>
                        </a>
                      </p>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <input type='text' placeholder='Your Name' />
                          <input type='text' placeholder='Email Address' />
                        </div>
                        <div className='col-lg-12'>
                          <textarea placeholder='Write Message'></textarea>
                          <button
                            type='submit'
                            className='thm-btn course-details__comment-form-btn'
                          >
                            Leave a Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default ChildDental;
